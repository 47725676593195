import './Header.scss';
import { useRef , useEffect, useState } from 'react';
import { gsap } from 'gsap';
import LOGO from '../../assets/header-logo.svg';
import OpenIcon from '../../assets/open-menu-icon.svg';
import CloseIcon from '../../assets/close-menu-icon.svg';
import PENCIL_LOGO from '../../assets/pencil-icon.svg';
import HEADER_TITLE from '.././../assets/header-title.svg';
import SplitText from '../SplitTextComponent/SplitTextComponent';
import classNames from 'classnames';
import { Link } from 'react-scroll';
import useScrollDirection from '../../hooks/useScrollDirection';
import {useMediaQuery} from '../../hooks/useMediaQuery';

const Header = (props) => {
    const btnTextTop = useRef();
    const btnTextBottom = useRef();
    const scrollDirection = useScrollDirection();

    const [openMenu, setOpenMenu] = useState(false);

    const isMobile = useMediaQuery('(max-width: 1050px)');

    const handleMenuClick = () => {
        if (isMobile) {
            setOpenMenu(!openMenu);
        } 
    };

    useEffect(() => {
        if (openMenu) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
    
        return () => {
          document.body.style.overflow = 'unset';
        };
    }, [openMenu]);


    // Touch Btn Hover Effect

    useEffect(() => {
        const topChars = btnTextTop.current.querySelectorAll('.char');
        const bottomChars = btnTextBottom.current.querySelectorAll('.char');
        const button = document.querySelector('.header-button-touch');

        const tl = gsap.timeline({ paused: true });
        tl.fromTo(topChars, {
            y: '0%',
            opacity: 1,
        }, {
            y: '-100%',
            opacity: 0,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        tl.fromTo(bottomChars, {
            y: '100%',
            opacity: 0,
        }, {
            y: '0%',
            opacity: 1,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        button.addEventListener('mouseenter', () => tl.play());
        button.addEventListener('mouseleave', () => tl.reverse());

        return () => {
            button.removeEventListener('mouseenter', () => tl.play());
            button.removeEventListener('mouseleave', () => tl.reverse());
        };
    }, []);


    // Styles

    const headerClasses = classNames('header', {
        visible: props.isLoadingEnded,
        hide: scrollDirection === 'down',
        open: openMenu
    });

    const headerContainerClasses = classNames('header-container', {
        border: props.isReadyToFix
    })

    const headerNavigationClasses = classNames('header-navigator', {
        open: openMenu
    })

    return (
        <header className={headerClasses}>
            <div className={headerContainerClasses}>
                <div className='header-line'></div>

                <div className='header-logo'>
                    <img src={LOGO} alt={'header-logo'}/>
                </div>

                <nav className={headerNavigationClasses}>
                    <div className='header-navigator-container'>
                        <ul>
                            <Link smooth spy to='services' onClick={handleMenuClick}>
                                <li>Services</li>
                            </Link>

                            <Link smooth spy to='about' onClick={handleMenuClick}>
                                <li>about</li>
                            </Link>
                            
                            <Link smooth spy to='testimonials' onClick={handleMenuClick}>
                                <li>testimonials</li>
                            </Link>
                        </ul>

                        <div className='header-navigation-mobile-box'>
                            <div className='header-navigator-contacts'>
                                <span className='header-navigator-contacts-label'>Contacts</span>

                                <div className='header-navigator-contacts-box'>
                                    <a href='mailto: welcome@amonsul.tech'>
                                        <span>email</span>
                                    </a>

                                    <a href='https://www.linkedin.com/company/amonsul-tech/' target='_blank' rel="noreferrer">
                                        <span>linkedin</span>
                                    </a>

                                    <a href='https://www.facebook.com/amonsul.tech' target='_blank' rel="noreferrer">
                                        <span>facebook</span>
                                    </a>
                                    
                                </div>
                            </div>

                            <div className='header-title'>
                                <img src={HEADER_TITLE} alt={'header title'}/>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className='header-buttons-box'>
                    <a href='mailto: welcome@amonsul.tech'>
                        <div className='header-button-email'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M3 3H15C15.825 3 16.5 3.675 16.5 4.5V13.5C16.5 14.325 15.825 15 15 15H3C2.175 15 1.5 14.325 1.5 13.5V4.5C1.5 3.675 2.175 3 3 3Z" stroke='currentColor' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.5 4.5L9 9.75L1.5 4.5" stroke='currentColor' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </a>

                    <Link smooth spy to='form'>
                        <div className='header-button-touch'>
                            
                            {/* <div className='header-button-touch-text-box'> */}
                                <SplitText tag='div' className='header-button-touch-text top' ref={btnTextTop}>get in touch</SplitText>
                                <SplitText tag='div' className='header-button-touch-text bottom' ref={btnTextBottom}>get in touch</SplitText>
                                {/* <SplitText tag='p' className='header-button-touch-text bottom' ref={btnTextBottom}>get in touch</SplitText> */}
                            {/* </div> */}

                            <div className='header-button-touch-pencil'>
                                <img src={PENCIL_LOGO} alt={'pencil'}/>
                            </div>
                        </div>
                    </Link>

                    <div className='header-button-menu' onClick={handleMenuClick}>
                        <img src={openMenu ? CloseIcon : OpenIcon} alt={'header menu'}/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
