import ServiceCard from './ServiceCard/ServiceCard';
import './ServicesSection.scss';
import { servicesData } from './servicesData';
import React , { useRef, useState, useEffect } from 'react';

const ServicesSection = (props) => {
    const sectionRef = useRef();
    const firstCardRef = useRef();
    const [parallaxActive, setParallaxActive] = useState(true);
    const [topPosition, setTopPosition] = useState(0);
    const [cardTop, setCardTop] = useState();
    const [scrollProgress, setScrollProgress] = useState({});


    useEffect(() => {
        const cardElements = sectionRef.current.querySelectorAll('.service-card');
        const observers = [];

        cardElements.forEach((card, index) => {
            // if (index === 0) return; // Skip the first card

            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        const scrollRatio = entry.intersectionRatio;

                        setScrollProgress((prev) => ({
                            ...prev,
                            [index - 1]: scrollRatio,
                        }));
                    }
                },
                {
                    root: null,
                    threshold: Array.from({ length: 101 }, (_, i) => i / 100),
                }
            );

            observer.observe(card);
            observers.push(observer);
        });

        return () => {
            observers.forEach((observer) => observer.disconnect());
        };
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            if (!firstCardRef.current) return;

            const scrollPosition = window.scrollY;
            const cardTop = firstCardRef.current.getBoundingClientRect().top + (scrollPosition * -0.25);

            const computedStyle = getComputedStyle(sectionRef.current);
            const transformMatrix = computedStyle.transform;

            let translateY = 0;
            if (transformMatrix !== 'none') {
                const matrixValues = transformMatrix.split('(')[1].split(')')[0].split(',');
                translateY = parseFloat(matrixValues[5]); // matrixValues[5] is the translateY value
             }

            if (cardTop <= topPosition) {
                setParallaxActive(false);
                setCardTop(translateY);
            } else {
                setParallaxActive(true);
            }


            const sectionRect = sectionRef.current.getBoundingClientRect();
            const bottomDistanceFromViewport = window.innerHeight - sectionRect.bottom;

            if (bottomDistanceFromViewport >= (window.innerHeight - 150)) {
                props.setAnimateAboutSection(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [parallaxActive, props, topPosition]);


    return (
        <section className='services-section' ref={sectionRef} style={{zIndex: '2'}} id='services'>
            {/* <div className='services-section-container'> */}
                {
                    servicesData.map((service, index) => (
                        <React.Fragment key={index}>
                            <ServiceCard
                                order={index + 1}
                                title={service.title}
                                info={service.info}
                                ref={index === 0 ? firstCardRef : null}
                                setTopPosition={setTopPosition}
                                topPosition={topPosition}
                                cardTop={cardTop}
                                scrollRatio={scrollProgress[index] || 0}
                            />

                            <div className='services-section-add-box'></div>
                        </React.Fragment>
                    ))
                }
            {/* </div> */}
        </section>  
    )
}

export default ServicesSection

