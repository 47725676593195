import './WelcomeSection.scss';
import { useState, useEffect, useRef} from 'react';
import SplitTextComponent from '../SplitTextComponent/SplitTextComponent';
import SplitType from 'split-type';
import gsap from 'gsap';
import Lottie from 'react-lottie';
import logoOpen from '../../assets/lottie/open.json';
import logoIddle from '../../assets/lottie/iddle.json';
import logoClose from '../../assets/lottie/close.json';
import logoBlink from '../../assets/lottie/blink.json';
import STAR from '../../assets/star-small.svg';
import useScrollDirection from '../../hooks/useScrollDirection';


const shortBenefitsData = [
    'GOVERN',
    'identity',
    'protect',
    'detect',
    'respond',
    'recover'
]

const blinkDelays = [200, 500, 700, 0, 500];


const WelcomeSection = (props) => {
    const [filled, setFilled] = useState(0);
    const [isReadyForProgress, setReadyForProgress] = useState(false);
    const [currentLogo, setCurrentLogo] = useState('open');
    const [isPaused, setPaused] = useState(true);
    const [interval, setInterval] = useState(0);
    
    const scrollDirection = useScrollDirection();
    const containerRef = useRef(null);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const innerTimeoutRef = useRef();


    // Lottie Files

    const logoOpenOptions = {
        loop: false,
        autoplay: true,
        animationData: logoOpen,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          className: "welcome-section-open-lottie",
        }
    };

    const logoIddleOptions = {
        loop: false,
        autoplay: true,
        animationData: logoIddle,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          className: "welcome-section-iddle-lottie",
        }
    };

    const logoCloseOptions = {
        loop: false,
        autoplay: true,
        animationData: logoClose,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          className: "welcome-section-close-lottie",
        }
    };

    const logoBlinkOptions = {
        loop: true,
        autoplay: false,
        animationData: logoBlink,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          className: "welcome-section-close-lottie",
        }
    };


    // H1 Font

    const fitTextToContainer = () => {
        const container = containerRef.current;
        const textElement = titleRef.current;
        const containerWidth = container.offsetWidth;
        const textWidth = textElement.scrollWidth;
    
        if (textWidth > containerWidth) {
            const scaleFactor = containerWidth / textWidth;
            textElement.style.transform = `scale(${scaleFactor})`;
            textElement.style.transformOrigin = 'left top';
        } else {
            textElement.style.transform = 'none';
        }
    };

    useEffect(() => {
        fitTextToContainer();
        window.addEventListener('resize', fitTextToContainer);

        return () => window.removeEventListener('resize', fitTextToContainer);
    }, []);
    


    // Progress Bar

    useEffect(() => {
        if (filled < 100 && isReadyForProgress) {
            setTimeout(() => setFilled(prev => prev + 2), 50);
        }
    }, [filled, isReadyForProgress]);


    // Lottie Changes Logic

    const handleOpenAnimationComplete = () => {
        setCurrentLogo('iddle');
        setReadyForProgress(true);
    };

    const handleIddleAnimationComplete = () => {
        setCurrentLogo('close');
    };

    const handleCloseAnimationComplete = () => {
        setCurrentLogo('blink');
        props.setLoadingEnded(true);

        setTimeout(() => {
            props.setReadyToFix(true);
        }, 1000);
    };

    const getCurrentLogoOptions = () => {
        switch (currentLogo) {
            case 'iddle':
                return logoIddleOptions;
            case 'close':
                return logoCloseOptions;
            case 'blink':
                return logoBlinkOptions;
            default:
                return logoOpenOptions;
        }
    };


    // Title Text Animation

    useEffect(() => {
        if (props.isLoadingEnded) {

            const titleChars = titleRef.current.querySelectorAll('.char');
            const subtitleChars = new SplitType(subtitleRef.current, { types: 'chars words' }).chars;
            // subtitleRef.current.querySelectorAll('.char');

            if (titleChars && subtitleChars) {
                gsap.timeline()
                    .staggerFromTo(titleChars, 0.8, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.1)
                    .staggerFromTo(subtitleChars, 0.2, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3');
            }
        }
    }, [props.isLoadingEnded]);


    // Disable scroll until isReadyToFix is true

    useEffect(() => {
        setTimeout(()=>{
            if (!props.isReadyToFix) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
            return () => document.body.style.overflow = 'unset';
        }, 1000)
    }, [props.isReadyToFix]);


    // Blinking Logic

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setPaused(false); 
          
          innerTimeoutRef.current = setTimeout(() => {
            setPaused(true); 
            setInterval(prevInterval => (prevInterval + 1 >= blinkDelays.length ? 0 : prevInterval + 1));
        }, 1250);
          
        }, blinkDelays[interval]); 
    
        return () => {
          clearTimeout(timeoutId);
          if (innerTimeoutRef.current) {
            clearTimeout(innerTimeoutRef.current);
          }
        };
      }, [interval]);
    

    return (
        <section className='welcome-section' style={{zIndex: '2'}}>
            <div className={`welcome-section-container ${props.isLoadingEnded ? 'border' : ''}`} ref={containerRef}>

                <div className={`welcome-section-lottie-container ${props.isReadyToFix ? 'scale' : ''}  ${currentLogo === 'blink' ? 'scale-modile' : ''}`}>
                    <Lottie 
                        options={getCurrentLogoOptions()}
                        className='lottie-logo'
                        isPaused={currentLogo === 'blink' ? isPaused : false}                   
                        eventListeners={[
                            {
                                eventName: 'complete',
                                callback: currentLogo === 'open' ? handleOpenAnimationComplete : 
                                        currentLogo === 'iddle' ? handleIddleAnimationComplete : 
                                        handleCloseAnimationComplete,
                            },
                        ]}
                    />
                </div>
                

                <div className={`progress-bar top ${props.isLoadingEnded ? 'up-to-header' : ''} ${props.isReadyToFix ? 'fixed' : ''} ${scrollDirection === 'down' ? 'hide' : 'visible'}`} style={{marginLeft: `calc(100% - ${filled}%)`}}></div>
                <div className={`progress-bar bottom ${props.isLoadingEnded ? 'down-to-bottom' : ''}`} style={{width: `${filled}%`}}></div>
                <span className={`progress-bar-percentage ${isReadyForProgress ? '' : 'hidden'}  ${props.isLoadingEnded ? 'hidden' : ''}`}>{filled}%</span>

                <div className={`welcome-section-star left ${props.isLoadingEnded ? 'visible' : ''}`}>
                    <img src={STAR} alt={'rotating star'}/>
                </div>

                <div className={`welcome-section-star right ${props.isLoadingEnded ? 'visible' : ''}`}>
                    <img src={STAR} alt={'rotating star'}/>
                </div>

                <SplitTextComponent tag='h1' className='welcome-section-title' ref={titleRef}>AMONSUL</SplitTextComponent>

                <h2 className='welcome-section-subtitle' ref={subtitleRef}>The WatchGuard of Cyberspace</h2>

                <div className={`welcome-section-benefits-box ${props.isLoadingEnded ? 'visible' : ''}`}>
                    <div className='welcome-section-benefits-small-box'>
                        {
                            shortBenefitsData.slice(0, 3).map((benefit, index) => (
                                <div key={index} className='welcome-section-benefit'>
                                    <span>{(index + 1).toString().padStart(2, '0')}</span>
                                    <span>{benefit}</span>
                                </div>
                            ))
                        }
                    </div>

            
                    <div className='welcome-section-benefits-small-box'>
                        {
                            shortBenefitsData.slice(-3).map((benefit, index) => (
                                <div key={index} className='welcome-section-benefit'>
                                    <span>{(index + 4).toString().padStart(2, '0')}</span>
                                    <span>{benefit}</span>
                                </div>
                            ))
                        }
                    </div>

                    <div className='welcome-section-benefits-small-box-mobile'>
                        {
                            shortBenefitsData.slice(0, 3).map((benefit, index) => (
                                <div key={index} className='welcome-section-benefit'>
                                    <span>{(index + 1).toString().padStart(2, '0')}</span>
                                    <span>{benefit}</span>
                                </div>
                            ))
                        }

                        {
                            shortBenefitsData.slice(-3).map((benefit, index) => (
                                <div key={index} className='welcome-section-benefit'>
                                    <span>{(index + 4).toString().padStart(2, '0')}</span>
                                    <span>{benefit}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className={`welcome-section-text-box ${props.isLoadingEnded ? 'visible' : ''}`}>
                    <div className='welcome-section-text-box-first'>
                        <span>your protection  against cyberattacks is here</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                            <path d="M3.99836 5.99987C3.99836 5.99987 4.69272 4.129 5.28478 3.1428C5.87683 2.1566 7 1 7 1M4.00164 5.99987C4.00164 5.99987 3.30728 4.129 2.71522 3.1428C2.12317 2.1566 1 1 1 1" stroke="white" strokeWidth="0.68538"/>
                            <path d="M1.00009 1.0334L7 1" stroke="white" strokeWidth="0.69"/>
                        </svg>
                    </div>

                    <span className='welcome-section-text-box-second'>We create a safe and secure cyberspace by protecting your digital assets</span>
                </div>
            </div>
        </section>
    )
}

export default WelcomeSection
