// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #982D67;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,yBCTW;EDUX,kBAAA;AALF;;AAQA;EACE,+EAAA;AALF;;AASA;EACE,UAAA;EACA,SAAA;AANF;;AASA;EACE,cAAA;EACA,qBAAA;AANF;;AASA;EACE,gBAAA;AANF","sourcesContent":["@use '../src/styles/mixins.scss';\n@import '../src/styles/variables.scss';\n\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: $main-color;\n  overflow-x: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n* {\n  padding: 0;\n  margin: 0;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\nul {\n  list-style: none;\n}\n","// Colors\n\n$main-color: #982D67;\n$white: #FFFFFF;\n$light-background: #F5F5F5;\n$purple: #812456;\n$grey: #EAEAEA;\n$grey-opacity:#E0E0E0;\n$grey-dark: #575756;\n$grey-text: #BCBCBC;\n$dark: #313130;\n$red: #AE2323;\n$opacity-text: rgba(255, 255, 255, 0.50);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
