import './RespondSection.scss';
import QUOTES from '../../assets/quotes.svg';
import SMALL_CIRCLE from '../../assets/small-circle.svg';
import CIRCLE from '../../assets/circle.svg';
import RespondSlider from './RespondSlider/RespondSlider';

const RespondSection = () => {
    return (
        <section className='respond-section' id='testimonials'>
            <div className='respond-section-container'>
                <img className='respond-section-circle' src={CIRCLE} alt={'circle'}/>

                <div className='respond-section-title'>
                    <h3>our partners</h3>
                </div>

                <div className='respond-section-body'>
                    <div className='respond-section-body-box'>
                        <div className='respond-section-body-label'>
                            <img className='respond-label-quotes' src={QUOTES} alt={'quotes'}/>
                            <img className='respond-label-circle' src={SMALL_CIRCLE} alt={'circle'}/>
                            <span className='respond-label-text-top'>satisfied customers talk about us</span>
                            <span className='respond-label-text-bottom'>15 reviews</span>
                        </div>

                        <div className='respond-section-body-button'>
                            <div className='respond-section-body-button-inner'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.00931 5.89079C8.49082 5.38225 7.89178 4.74774 7.49135 4.18452C7.08837 3.61774 6.63506 2.77031 6.27253 2.03776C6.09392 1.67684 5.94193 1.35327 5.83467 1.12005C5.78109 1.00355 5.73879 0.909869 5.71005 0.845667L5.6774 0.772381L5.66926 0.753929L5.6669 0.748569L5.0239 1.02997C4.38096 1.3115 4.38098 1.31155 4.381 1.3116L4.38446 1.31946L4.39375 1.34052L4.42887 1.41937C4.45935 1.48743 4.5036 1.58544 4.55935 1.70665C4.67076 1.94888 4.82856 2.28487 5.01442 2.66043C5.38084 3.40084 5.87524 4.33404 6.34728 4.99796C6.82186 5.66545 7.49322 6.37007 8.02637 6.89298C8.29658 7.158 8.53849 7.38331 8.71312 7.54261C8.80052 7.62235 8.87131 7.68577 8.92065 7.7296L8.97802 7.7803L8.99355 7.79391L8.99908 7.79873C8.99913 7.79877 8.99972 7.79928 9.46011 7.26949L9.92056 6.73975L9.91705 6.73669L9.90435 6.72556L9.85299 6.68017C9.80781 6.64003 9.74163 6.58075 9.6592 6.50555C9.49415 6.35498 9.26492 6.14149 9.00931 5.89079Z" fill="currentColor"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.967869 5.83633C1.49299 5.33459 2.1003 4.70794 2.5081 4.14999C2.91847 3.58851 3.38286 2.74706 3.75497 2.0193C3.93831 1.66074 4.09452 1.33917 4.20484 1.10738C4.25994 0.991582 4.30347 0.898458 4.33305 0.834637L4.36665 0.761782L4.37504 0.743438L4.37747 0.738109L5.01672 1.02787C5.65591 1.31775 5.65589 1.3178 5.65587 1.31785L5.65231 1.32567L5.64274 1.3466L5.60659 1.42499C5.57523 1.49264 5.52969 1.59007 5.47235 1.71055C5.35778 1.9513 5.19558 2.2852 5.0048 2.65831C4.6287 3.39388 4.1221 4.32056 3.64139 4.97827C3.15809 5.63952 2.47754 6.33533 1.93758 6.85124C1.66392 7.11272 1.41907 7.33486 1.24237 7.49187C1.15392 7.57046 1.08231 7.63295 1.0324 7.67614L0.974365 7.72609L0.958658 7.73949L0.95307 7.74424C0.953015 7.74429 0.952424 7.74479 0.499025 7.20904L0.045561 6.67334L0.049112 6.67033L0.0619584 6.65936L0.113903 6.61465C0.159606 6.5751 0.226564 6.51669 0.309977 6.44257C0.476986 6.29417 0.708992 6.08368 0.967869 5.83633Z" fill="currentColor"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.70029 13H4.30029L4.30029 5H5.70029V13Z" fill="currentColor"/>
                                </svg>
                            </div>

                            <p>become our partner</p>
                        </div>
                    </div>

                    <RespondSlider/>
                </div>
            </div>
        </section>
    )
}

export default RespondSection
