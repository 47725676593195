// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAGA;EAEI,kBAAA;AAHJ","sourcesContent":["@use '../src/styles/mixins.scss';\n@import '../src/styles/variables.scss';\n\n.App {\n    // background-color: $main-color;\n    position: relative;\n}\n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
