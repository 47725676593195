import './FormSection.scss';
import CreepingFormLine from './CreepingFormLine/CreepingFormLine';
import Form from './Form/Form';
import Lottie from 'react-lottie';
import SplitTextComponent from '../SplitTextComponent/SplitTextComponent';
import logoBlink from '../../assets/lottie/blink.json';
import {useState, useRef, useEffect} from 'react';
import gsap from 'gsap';

const blinkDelaysLeft = [200, 500, 700, 0, 500];
const blinkDelaysRight = [0, 200, 1000, 500, 700];

const FormSection = (props) => {
    const formSectionRef = useRef(null);

    const formTitleFirstRef = useRef(null);
    const formTitleSecondRef = useRef(null);
    const formTitleThirdRef = useRef(null);

    const formTitleFirstRefMobile = useRef(null);
    const formTitleSecondRefMobile = useRef(null);
    const formTitleThirdRefMobile = useRef(null);
    const formTitleFourthRefMobile = useRef(null);

    const innerTimeoutRefLeft = useRef();
    const innerTimeoutRefRight = useRef();


    const [isAtTop, setIsAtTop] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [isPausedLeft, setPausedLeft] = useState(true);
    const [isPausedRight, setPausedRight] = useState(true);
    const [intervalLeft, setIntervalLeft] = useState(0);
    const [intervalRight, setIntervalRight] = useState(0);

    // Lottie Animation

    const logoBlinkOptions = {
        loop: true,
        autoplay: false,
        animationData: logoBlink,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          className: "welcome-section-close-lottie",
        }
    };

    // Text Animation On Scroll

    const handleScroll = () => {
        if (formSectionRef.current) {
            const rect = formSectionRef.current.getBoundingClientRect();

            if (window.innerWidth > 800) {
                if (rect.top < 0 && !hasAnimated) {
                    setIsAtTop(true);
                }
            } else {
                if ((rect.top - 350) < 0 && !hasAnimated) {
                    setIsAtTop(true);
                }
            }

            if (rect.top < 0) {
                props.setFooterIndex(true);
            } else {
                props.setFooterIndex(false);
            }
        }


    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); 

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isAtTop && !hasAnimated) {
            
            const titleFirstChars = formTitleFirstRef.current.querySelectorAll('.char');
            const titleSecondChars = formTitleSecondRef.current.querySelectorAll('.char');
            const titleThirdChars = formTitleThirdRef.current.querySelectorAll('.char');

            const titleFirstCharsMobile = formTitleFirstRefMobile.current.querySelectorAll('.char');
            const titleSecondCharsMobile = formTitleSecondRefMobile.current.querySelectorAll('.char');
            const titleThirdCharsMobile = formTitleThirdRefMobile.current.querySelectorAll('.char');
            const titleFourthCharsMobile = formTitleFourthRefMobile.current.querySelectorAll('.char');

            if (titleFirstChars && titleSecondChars && titleThirdChars && titleFirstCharsMobile && titleSecondCharsMobile && titleThirdCharsMobile && titleFourthCharsMobile) {
                gsap.timeline()
                    .staggerFromTo(titleFirstChars, 0.4, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3')
                    .staggerFromTo(titleSecondChars, 0.4, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3')
                    .staggerFromTo(titleThirdChars, 0.2, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3')
                    .staggerFromTo(titleFirstCharsMobile, 0.4, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3')
                    .staggerFromTo(titleSecondCharsMobile, 0.4, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3')
                    .staggerFromTo(titleThirdCharsMobile, 0.2, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3')
                    .staggerFromTo(titleFourthCharsMobile, 0.2, {
                        y: '100%',
                        opacity: 0
                    }, {
                        y: '0%',
                        opacity: 1,
                        ease: 'Power3.easeOut'
                    }, 0.03, '-=0.3');
            }

            setHasAnimated(true);
        }
    }, [isAtTop, hasAnimated]);


    // Blinking Logic

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setPausedLeft(false); 
          
          innerTimeoutRefLeft.current = setTimeout(() => {
            setPausedLeft(true); 
            setIntervalLeft(prevInterval => (prevInterval + 1 >= blinkDelaysLeft.length ? 0 : prevInterval + 1));
        }, 1250);
          
        }, blinkDelaysLeft[intervalLeft]); 
    
        return () => {
          clearTimeout(timeoutId);
          if (innerTimeoutRefLeft.current) {
            clearTimeout(innerTimeoutRefLeft.current);
          }
        };
      }, [intervalLeft]);

      useEffect(() => {
        const timeoutId = setTimeout(() => {
          setPausedRight(false); 
          
          innerTimeoutRefRight.current = setTimeout(() => {
            setPausedRight(true); 
            setIntervalRight(prevInterval => (prevInterval + 1 >= blinkDelaysRight.length ? 0 : prevInterval + 1));
        }, 1250);
          
        }, blinkDelaysRight[intervalRight]); 
    
        return () => {
          clearTimeout(timeoutId);
          if (innerTimeoutRefRight.current) {
            clearTimeout(innerTimeoutRefRight.current);
          }
        };
      }, [intervalRight]);

    return (
        <section className='form-section' ref={formSectionRef} style={{zIndex: '2'}}>
            <CreepingFormLine isReadyToFix={props.isReadyToFix}/>

            <div className='form-section-lottie-container left'>
                <Lottie 
                    options={logoBlinkOptions}
                    className='lottie-logo'
                    isPaused={isPausedLeft}    
                />
            </div>

            <div className='form-section-lottie-container right'>
                <Lottie 
                    options={logoBlinkOptions}
                    className='lottie-logo'
                    isPaused={isPausedRight} 
                />
            </div>

            <div className='form-section-container'>
                <div className='form-section-title'>
                    <SplitTextComponent tag='div' className='form-section-title-main-text first' ref={formTitleFirstRef}>Let us become</SplitTextComponent>
                    <SplitTextComponent tag='div' className='form-section-title-main-text second' ref={formTitleSecondRef}>a watchguard</SplitTextComponent>
                    <SplitTextComponent tag='div' className='form-section-title-main-text third' ref={formTitleThirdRef}>of your cyberspace</SplitTextComponent>
                    
                    <span className='form-section-title-small-text'>we can become a reliable security shield in your digital space</span>
                </div>

                <div className='form-section-title-mobile'>
                    <SplitTextComponent tag='div' className='form-section-title-main-text first' ref={formTitleFirstRefMobile}>Let us become</SplitTextComponent>
                    <SplitTextComponent tag='div' className='form-section-title-main-text second' ref={formTitleSecondRefMobile}>a watchguard</SplitTextComponent>
                    <SplitTextComponent tag='div' className='form-section-title-main-text third' ref={formTitleThirdRefMobile}>of your</SplitTextComponent>
                    <SplitTextComponent tag='div' className='form-section-title-main-text fourth' ref={formTitleFourthRefMobile}>cyberspace</SplitTextComponent>
                </div>

                <Form/>
            </div>
        </section>
    )
}

export default FormSection
