import './ServiceCard.scss';
import { useRef, useEffect, forwardRef } from 'react';
import Lottie from 'react-lottie';
import STAR_BLACK from '../../../assets/star-black.svg';
import logoBlink from '../../../assets/lottie/blink.json';
import classNames from 'classnames';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; 

gsap.registerPlugin(ScrollTrigger);

const ServiceCard = forwardRef((props, ref) => {
    const cardRef = useRef();
    const infoBoxRef = useRef([]);

    // Lottie

    const logoBlinkOptions = {
        loop: false,
        autoplay: false,
        animationData: logoBlink,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          className: "welcome-section-close-lottie",
        }
    };

    // Scale And Transform Prev Card

    useEffect(() => {
        const updateTransform = () => {
            const isSmallScreen = window.innerWidth < 1050;
            const scale = isSmallScreen ? 1 : 1 - props.scrollRatio * 0.07;
            const translateY = isSmallScreen ? props.scrollRatio * 10 : props.scrollRatio * 40;
    
            if (cardRef.current) {
                cardRef.current.style.transform = `translateY(${translateY}px) scale(${scale})`;
            }
    
            if (props.order === 1 && ref.current) {
                ref.current.style.transform = `translateY(${translateY}px) scale(${scale})`;
            }
        };
    
        updateTransform();
    
        window.addEventListener('resize', updateTransform);
    
        return () => {
            window.removeEventListener('resize', updateTransform);
        };
    }, [props.scrollRatio, ref, props.order]);

    // Fix Service Card To Bottom
    
    useEffect(() => {
        const updateTopPosition = () => {
            let topValue = 1;

            if (window.innerWidth > 1620) {
                topValue = window.innerHeight > 960 ? window.innerHeight - 960 : 0;
            } else if (window.innerWidth < 1050 && window.innerWidth > 550) {
                topValue = window.innerHeight > 684 ? window.innerHeight - 684 : 0;
            } else if (window.innerWidth < 550) {
                topValue = window.innerHeight > 610 ? window.innerHeight - 610 : 0;
            } else {
                topValue = window.innerHeight > 783 ? window.innerHeight - 783 : 0;
            }

            props.setTopPosition(topValue);
            if (cardRef.current) {
                cardRef.current.style.top = `${topValue - (props.cardTop)}px`;
            }

            if (props.order === 1 && ref.current) {
                ref.current.style.top = `${topValue - (props.cardTop)}px`;
            }
        };

        updateTopPosition();

        window.addEventListener('resize', updateTopPosition);

        return () => {
            window.removeEventListener('resize', updateTopPosition);
        };
    }, [props.cardTop, props, ref]);

    useEffect(() => {
        const handleScroll = () => {
            const targetRef = props.order === 1 ? ref : cardRef;
            if (!targetRef.current) return;

            const rect = targetRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const progress = (windowHeight - rect.top) / (windowHeight - props.topPosition);
            const scrollProgress = Math.min(Math.max(progress, 0), 1);

            if (window.innerWidth > 1050) {
                const infoBox = targetRef.current.querySelector('.service-card-info-box');
                if (infoBox) {
                    infoBox.style.left = `${99 - 99 * scrollProgress}%`;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [props.topPosition, ref, props.order]);


    useEffect(() => {
        const targetRef = props.order === 1 ? ref : cardRef;

        if (!targetRef.current) return;

        const items = infoBoxRef.current;

        if (!items.length) {
            console.error('No service-card-info-item elements found');
            return;
        }

        ScrollTrigger.refresh();

        if (window.innerWidth > 1050) {
            const scrollTween = gsap.to(items, {
                xPercent: (i) => -94 * i,
                duration: (i) => 0.5 * i,
                ease: "none",
                scrollTrigger: {
                    trigger: targetRef.current,
                    pin: false,
                    scrub: 0.1,
                    start: `bottom bottom`,
                    markers: false,
                    end: "+=400"
                },
            }) 
    
            return () => scrollTween.kill();
        } else {
            const scrollTween = gsap.to(items, {
                yPercent: (i) => -93 * i,
                duration: (i) => 0.5 * i,
                ease: "none",
                scrollTrigger: {
                    trigger: targetRef.current,
                    pin: false,
                    scrub: 0.1,
                    start: `bottom bottom`,
                    markers: false,
                    end: "+=1000",
                    toggleActions: "play reverse play reverse"
                },
            }) 

            return () => scrollTween.kill();
        }

    }, [props.topPosition, ref, props.order]);
    
    
    

    // Styles 

    const getNavClass = (text) => {
        return classNames('portfolio-nav-item', {
            govern: props.title.toLowerCase() === text.toLowerCase() && text.toLowerCase() === 'govern',
            identify: props.title.toLowerCase() === text.toLowerCase() && text.toLowerCase() === 'identify',
            protect: props.title.toLowerCase() === text.toLowerCase() && text.toLowerCase() === 'protect',
            detect: props.title.toLowerCase() === text.toLowerCase() && text.toLowerCase() === 'detect',
            respond: props.title.toLowerCase() === text.toLowerCase() && text.toLowerCase() === 'respond',
            recover: props.title.toLowerCase() === text.toLowerCase() && text.toLowerCase() === 'recover',
        });
    };

    return (
        <div 
            className={`service-card `}
            ref={props.order === 1 ? ref : cardRef}
        >
            <div className='service-card-container'>

                <div className='sercvice-card-lottie-container'>
                    <Lottie 
                        options={logoBlinkOptions}
                        className='lottie-logo'
                    />
                </div>

                <div className='service-card-title-box'>
                    <ul>
                        <li className={getNavClass('govern')}>GOVERN</li>
                        <li className={getNavClass('identify')}>identify</li>
                        <li className={getNavClass('protect')}>protect</li>
                        <li className={getNavClass('detect')}>detect</li>
                        <li className={getNavClass('respond')}>respond</li>
                        <li className={getNavClass('recover')}>recover</li>
                    </ul>

                    <h2>{props.title}</h2>
                </div>

                <div className='service-card-info-box'>
                    <div className='service-card-info-box-inner-wrapper'>
                        {
                            props.info.map((info, index) => (
                                <div className='service-card-info-item' key={index} ref={(el) => (infoBoxRef.current[index] = el)}>
                                    <div>
                                        <span>{info.title}</span>
                                        <p className='service-card-info-box-text-mobile'>{info.text}</p>
                                        <img src={STAR_BLACK} alt={'star'}/>
                                    </div>
                                   
                                    <p>{info.text}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ServiceCard;

