// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./FixelDisplay/FixelDisplay-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./FixelDisplay/FixelDisplay-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./FixelDisplay/FixelDisplay-MediumItalic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./FixelDisplay/FixelDisplay-MediumItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./FixelDisplay/FixelDisplay-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./FixelDisplay/FixelDisplay-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./FixelDisplay/FixelDisplay-RegularItalic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./FixelDisplay/FixelDisplay-RegularItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./FixelDisplay/FixelDisplay-SemiBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./FixelDisplay/FixelDisplay-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./FixelDisplay/FixelDisplay-SemiBoldItalic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./FixelDisplay/FixelDisplay-SemiBoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("./FixelText/FixelText-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("./FixelText/FixelText-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("./FixelText/FixelText-RegularItalic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_15___ = new URL("./FixelText/FixelText-RegularItalic.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "FixelDisplay";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("woff2");
}
@font-face {
  font-family: "FixelText";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format("otf"), url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format("woff2");
}`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,qqBACE;AAAN;AAcA;EACE,wBAAA;EACA,uOACE;AAbJ","sourcesContent":["@font-face {\n    font-family: 'FixelDisplay';\n    src: \n      url('./FixelDisplay/FixelDisplay-Medium.otf') format('otf'),\n      url('./FixelDisplay/FixelDisplay-Medium.woff2') format('woff2'),\n      url('./FixelDisplay/FixelDisplay-MediumItalic.otf') format('otf'),\n      url('./FixelDisplay/FixelDisplay-MediumItalic.woff2') format('woff2'),\n      url('./FixelDisplay/FixelDisplay-Regular.otf') format('otf'),\n      url('./FixelDisplay/FixelDisplay-Regular.woff2') format('woff2'),\n      url('./FixelDisplay/FixelDisplay-RegularItalic.otf') format('otf'),\n      url('./FixelDisplay/FixelDisplay-RegularItalic.woff2') format('woff2'),\n      url('./FixelDisplay/FixelDisplay-SemiBold.otf') format('otf'),\n      url('./FixelDisplay/FixelDisplay-SemiBold.woff2') format('woff2'),\n      url('./FixelDisplay/FixelDisplay-SemiBoldItalic.otf') format('otf'),\n      url('./FixelDisplay/FixelDisplay-SemiBoldItalic.woff2') format('woff2')\n}\n\n@font-face {\n  font-family: 'FixelText';\n  src: \n    url('./FixelText/FixelText-Regular.otf') format('otf'),\n    url('./FixelText/FixelText-Regular.woff2') format('woff2'),\n    url('./FixelText/FixelText-RegularItalic.otf') format('otf'),\n    url('./FixelText/FixelText-RegularItalic.woff2') format('woff2'),\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
