import './Footer.scss';
import FOOTER_TITLE_P1 from '.././../assets/footer/footer-title-p1.svg';
import FOOTER_TITLE_P2 from '.././../assets/footer/footer-title-p2.svg';
import FOOTER_TITLE_P3 from '.././../assets/footer/footer-title-p3.svg';
import DEV_LOGO from '../../assets/dev-logo.svg';
import CIRCLE from '.././../assets/circle.svg';
import ARROW from '../../assets/footer/footer-arrow.svg';
import SplitTextComponent from '../SplitTextComponent/SplitTextComponent';
import {useRef, useEffect} from 'react';
import { gsap } from 'gsap';
import { animateScroll as scroll } from 'react-scroll';
import classNames from 'classnames';


const Footer = (props) => {
    const handleScrollToTop = () => {
        scroll.scrollToTop();
    }

    const emailRef = useRef(null);
    const emailTextTop = useRef(null);
    const emailTextBottom = useRef(null);
    const linRef = useRef(null);
    const linTextTop = useRef(null);
    const linTextBottom = useRef(null);
    const fbRef = useRef(null);
    const fbTextTop = useRef(null);
    const fbTextBottom = useRef(null);

    useEffect(() => {
        const topChars = emailTextTop.current.querySelectorAll('.char');
        const bottomChars = emailTextBottom.current.querySelectorAll('.char');
        const button = emailRef.current;

        const tl = gsap.timeline({ paused: true });
        tl.fromTo(topChars, {
            y: '0%',
            opacity: 1,
        }, {
            y: '-100%',
            opacity: 0,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.02
        });

        tl.fromTo(bottomChars, {
            y: '100%',
            opacity: 0,
        }, {
            y: '0%',
            opacity: 1,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.02
        });

        button.addEventListener('mouseenter', () => tl.play());
        button.addEventListener('mouseleave', () => tl.reverse());

        return () => {
            button.removeEventListener('mouseenter', () => tl.play());
            button.removeEventListener('mouseleave', () => tl.reverse());
        };
    }, []);

    useEffect(() => {
        const topChars = linTextTop.current.querySelectorAll('.char');
        const bottomChars = linTextBottom.current.querySelectorAll('.char');
        const button = linRef.current;

        const tl = gsap.timeline({ paused: true });
        tl.fromTo(topChars, {
            y: '0%',
            opacity: 1,
        }, {
            y: '-100%',
            opacity: 0,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        tl.fromTo(bottomChars, {
            y: '100%',
            opacity: 0,
        }, {
            y: '0%',
            opacity: 1,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        button.addEventListener('mouseenter', () => tl.play());
        button.addEventListener('mouseleave', () => tl.reverse());

        return () => {
            button.removeEventListener('mouseenter', () => tl.play());
            button.removeEventListener('mouseleave', () => tl.reverse());
        };
    }, []);

    useEffect(() => {
        const topChars = fbTextTop.current.querySelectorAll('.char');
        const bottomChars = fbTextBottom.current.querySelectorAll('.char');
        const button = fbRef.current;

        const tl = gsap.timeline({ paused: true });
        tl.fromTo(topChars, {
            y: '0%',
            opacity: 1,
        }, {
            y: '-100%',
            opacity: 0,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        tl.fromTo(bottomChars, {
            y: '100%',
            opacity: 0,
        }, {
            y: '0%',
            opacity: 1,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        button.addEventListener('mouseenter', () => tl.play());
        button.addEventListener('mouseleave', () => tl.reverse());

        return () => {
            button.removeEventListener('mouseenter', () => tl.play());
            button.removeEventListener('mouseleave', () => tl.reverse());
        };
    }, []);

    // Styles

    const footerStyles = classNames('footer', {
        visible: props.footerIndex
    })

    return (
        <footer className={footerStyles}>
            <div className='footer-container'>
                <div className='footer-contact-box'>
                    <div className='footer-contact-box-p1'>
                        <div className='footer-contact-box-p1-image'>
                            <img src={CIRCLE} alt={'circle'}/>
                        </div>

                        <div className='footer-contact-box-p1-text'>
                            <p>to enhance your Cyber Resilience, contact us</p>
                            <img src={ARROW} alt={'arrow'}/>
                        </div>
                    </div>

                    <div className='footer-contact-box-p2'>
                        <span className='footer-contact-box-p2-label'>contacts</span>


                        <a href='mailto: welcome@amonsul.tech' >
                            <div className='footer-contact-box-p2-email' ref={emailRef}>
                                <SplitTextComponent tag='div' className='footer-contact-box-p2-email-text top' ref={emailTextTop}>welcome@amonsul.tech</SplitTextComponent>
                                <SplitTextComponent tag='div' className='footer-contact-box-p2-email-text bottom' ref={emailTextBottom}>welcome@amonsul.tech</SplitTextComponent>
                            </div>
                        </a>

                        <div className='footer-contact-box-p2-socmedia-block'>
                            <a href='https://www.linkedin.com/company/amonsul-tech/' target='_blank' rel="noreferrer">
                                <div className='footer-contact-box-p2-lin' ref={linRef}>
                                    <SplitTextComponent tag='div' className='footer-contact-box-p2-lin-text top' ref={linTextTop}>linkedin</SplitTextComponent>
                                    <SplitTextComponent tag='div' className='footer-contact-box-p2-lin-text bottom' ref={linTextBottom}>linkedin</SplitTextComponent>
                                </div>
                            </a>

                            <a href='https://www.facebook.com/amonsul.tech' target='_blank' rel="noreferrer">
                                <div className='footer-contact-box-p2-fb' ref={fbRef}>
                                    <SplitTextComponent tag='div' className='footer-contact-box-p2-fb-text top' ref={fbTextTop}>facebook</SplitTextComponent>
                                    <SplitTextComponent tag='div' className='footer-contact-box-p2-fb-text bottom' ref={fbTextBottom}>facebook</SplitTextComponent>
                                </div>
                            </a>
                        </div>
                    </div>     
                </div>

                <div className='footer-info-box'>
                    <p>amonsul.<br/>The WatchGuard of Cyberspace </p>

                    <div className='footer-info-box-container'>
                        <div className='footer-info-box-container-inner'>
                            <span className='footer-info-box-lable'>locations</span>
                            <div>kyiv</div>
                            <div>lisbon</div>
                        </div>

                        <div className='footer-info-box-container-inner'>
                            <span className='footer-info-box-lable'>legal</span>
                            <div>privacy policy</div>
                            <div>©2024 AMONSUL</div>
                        </div>

                        <div className='footer-info-box-container-inner'>
                            <span className='footer-info-box-lable'>dev. by</span>
                            <a href='https://www.reflekt-agency.com/' target='_blank' rel="noreferrer">
                                <img src={DEV_LOGO} alt={'dev logo'}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footer-title'>
                <div className='footer-title-container p1'>
                    <img src={FOOTER_TITLE_P1} alt={'footer title'}/>
                </div>
                <div className='footer-title-container p2'>
                    <img src={FOOTER_TITLE_P2} alt={'footer title'}/>

                    <div className='footer-button-container'>
                        <div className='footer-button' onClick={handleScrollToTop}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.00931 5.89079C8.49082 5.38225 7.89178 4.74774 7.49135 4.18452C7.08837 3.61774 6.63506 2.77031 6.27253 2.03776C6.09392 1.67684 5.94193 1.35327 5.83467 1.12005C5.78109 1.00355 5.73879 0.909869 5.71005 0.845667L5.6774 0.772381L5.66926 0.753929L5.6669 0.748569L5.0239 1.02997C4.38096 1.3115 4.38098 1.31155 4.381 1.3116L4.38446 1.31946L4.39375 1.34052L4.42887 1.41937C4.45935 1.48743 4.5036 1.58544 4.55935 1.70665C4.67076 1.94888 4.82856 2.28487 5.01442 2.66043C5.38084 3.40084 5.87524 4.33404 6.34728 4.99796C6.82186 5.66545 7.49322 6.37007 8.02637 6.89298C8.29658 7.158 8.53849 7.38331 8.71312 7.54261C8.80052 7.62235 8.87131 7.68577 8.92065 7.7296L8.97802 7.7803L8.99355 7.79391L8.99908 7.79873C8.99913 7.79877 8.99972 7.79928 9.46011 7.26949L9.92056 6.73975L9.91705 6.73669L9.90435 6.72556L9.85299 6.68017C9.80781 6.64003 9.74163 6.58075 9.6592 6.50555C9.49415 6.35498 9.26492 6.14149 9.00931 5.89079Z" fill="currentColor"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.967869 5.83633C1.49299 5.33459 2.1003 4.70794 2.5081 4.14999C2.91847 3.58851 3.38286 2.74706 3.75497 2.0193C3.93831 1.66074 4.09452 1.33917 4.20484 1.10738C4.25994 0.991582 4.30347 0.898458 4.33305 0.834637L4.36665 0.761782L4.37504 0.743438L4.37747 0.738109L5.01672 1.02787C5.65591 1.31775 5.65589 1.3178 5.65587 1.31785L5.65231 1.32567L5.64274 1.3466L5.60659 1.42499C5.57523 1.49264 5.52969 1.59007 5.47235 1.71055C5.35778 1.9513 5.19558 2.2852 5.0048 2.65831C4.6287 3.39388 4.1221 4.32056 3.64139 4.97827C3.15809 5.63952 2.47754 6.33533 1.93758 6.85124C1.66392 7.11272 1.41907 7.33486 1.24237 7.49187C1.15392 7.57046 1.08231 7.63295 1.0324 7.67614L0.974365 7.72609L0.958658 7.73949L0.95307 7.74424C0.953015 7.74429 0.952424 7.74479 0.499025 7.20904L0.045561 6.67334L0.049112 6.67033L0.0619584 6.65936L0.113903 6.61465C0.159606 6.5751 0.226564 6.51669 0.309977 6.44257C0.476986 6.29417 0.708992 6.08368 0.967869 5.83633Z" fill="currentColor"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.70029 13H4.30029L4.30029 5H5.70029V13Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='footer-title-container p3'>
                    <img src={FOOTER_TITLE_P3} alt={'footer title'}/>
                </div>
            </div>
        </footer>
    )
}

export default Footer
