import './CreepingLine.scss';
import { useEffect, useRef } from 'react';
import STAR from '../../assets/star-small.svg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const CreepingLine = ({ isLoadingEnded }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!isLoadingEnded) return;

    const items = gsap.utils.toArray('.creeping-line-box');
    const itemWidth = items[0]?.clientWidth || 0;

    items.forEach((item, i) => {
      gsap.set(item, {
        x: itemWidth * i 
      });
    });

    let mod = gsap.utils.wrap(-itemWidth, 0);

    function marquee(which, time, direction) {
      return gsap.to(which, {
        duration: time,
        ease: "none",
        x: direction,
        modifiers: {
          x: x => mod(parseFloat(x)) + "px"
        },
        repeat: -1,
      });
    }

    const master = gsap.timeline()
      .add(marquee('.creeping-line-box', 40, '-=' + itemWidth), 0);

    const tween = gsap.to(master, { duration: 2, timeScale: 1, paused: true });
    const timeScaleClamp = gsap.utils.clamp(1, 6);

    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: self => {
        master.timeScale(timeScaleClamp(Math.abs(self.getVelocity() / 10)));
        tween.invalidate().restart();
      }
    });

    // Cleanup on unmount
    return () => {
      master.kill();
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [isLoadingEnded]);

  return (
    <div className='creeping-line' ref={containerRef} style={{zIndex: '2'}}>
      <div className='creeping-line-box'>
        <span>Elevate your digital security with our expert services</span>
        <img src={STAR} alt={'rotating star'}/>
      </div>

      <div className='creeping-line-box'>
        <span>Elevate your digital security with our expert services</span>
        <img src={STAR} alt={'rotating star'}/>
      </div>

      <div className='creeping-line-box'>
        <span>Elevate your digital security with our expert services</span>
        <img src={STAR} alt={'rotating star'}/>
      </div>
    </div>
  );
}

export default CreepingLine;
