import './FormButton.scss';
import React, {useRef, useEffect} from 'react';
import SplitText from '../../../SplitTextComponent/SplitTextComponent';
import { gsap } from 'gsap';


const FormButton = () => {
    const btnInnerRef = useRef(null);
    const btnRef = useRef(null);
    const btnTextTop = useRef();
    const btnTextBottom = useRef();

    const handleMouseEnter = (e) => {
        const rect = e.target.getBoundingClientRect();
        const relX = e.clientX - rect.left;
        const relY = e.clientY - rect.top;
        btnInnerRef.current.style.top = `${relY}px`;
        btnInnerRef.current.style.left = `${relX}px`;
    };

    const handleMouseLeave = (e) => {
        const rect = e.target.getBoundingClientRect();
        const relX = e.clientX - rect.left;
        const relY = e.clientY - rect.top;
        btnInnerRef.current.style.top = `${relY}px`;
        btnInnerRef.current.style.left = `${relX}px`;
    };

    useEffect(() => {
        btnRef.current.addEventListener('mouseenter', handleMouseEnter);
        btnRef.current.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            btnRef.current.addEventListener('mouseenter', handleMouseEnter);
            btnRef.current.addEventListener('mouseleave', handleMouseLeave);
        }
    }, []);

    useEffect(() => {
        const topChars = btnTextTop.current.querySelectorAll('.char');
        const bottomChars = btnTextBottom.current.querySelectorAll('.char');
        const button = btnRef.current;

        const tl = gsap.timeline({ paused: false });
        tl.fromTo(topChars, {
            y: '0%',
            opacity: 1,
        }, {
            y: '-100%',
            opacity: 0,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        tl.fromTo(bottomChars, {
            y: '100%',
            opacity: 0,
        }, {
            y: '0%',
            opacity: 1,
            duration: 0.2,
            ease: 'Power3.easeOut',
            stagger: -0.03
        });

        button.addEventListener('mouseenter', () => tl.play());
        button.addEventListener('mouseleave', () => tl.reverse());

        return () => {
            button.removeEventListener('mouseenter', () => tl.play());
            button.removeEventListener('mouseleave', () => tl.reverse());
        };
    }, []);


    return (
        <button
            className='form-button'
            ref={btnRef}
        >
            <SplitText tag='div' className='form-button-text top' ref={btnTextTop}>send the message</SplitText>
            <SplitText tag='div' className='form-button-text bottom' ref={btnTextBottom}>send the message</SplitText>
            <span 
                className='form-button-inner-circle'
                ref={btnInnerRef}
            ></span>
        </button>
    )
}

export default FormButton
