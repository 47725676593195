import './AboutSection.scss';
import classNames from 'classnames';
import POINT_ICON from '../../assets/point-icon.svg';
import SplitText from '../SplitTextComponent/SplitTextComponent';
import {useRef, useEffect, useState} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger);

const text_v1 = {
    main_text: 'Our team of experts is dedicated to maintaining the highest ethical standards and values, such as transparency, flexibility, and trust. We believe in continuous learning, innovation, and a growth mindset, and we strive to remain at the forefront of modern trends.',
    add_points: [
        'expert team',
        'the highest ethical standarts',
        'modern trends'
    ],
    title_p1: 'growth &',
    title_p2: 'innovation'
};

const text_v2 = {
    main_text: 'We believe that genuine values arise when we focus on our clients needs. That is why we view each client as not just a business opportunity but as a potential long-term partner. We are committed to growing together and assisting each other in succeeding by utilizing a win-win strategy that ensures mutual benefit and trust.',
    add_points: [
        'succeeding',
        'win-win strategy',
        'mutual benefit & trust'
    ],
    title_p1: 'long-term',
    title_p2: 'partnership'
};

const text_v3 = {
    main_text: 'We believe that genuine values arise when we focus on our clients needs. That is why we view each client as not just a business opportunity but as a potential long-term partner. We are committed to growing together and assisting each other in succeeding by utilizing a win-win strategy that ensures mutual benefit and trust.',
    add_points: [
        'wide range of services',
        'unique experiences',
        'the highest quality standards'
    ],
    title_p1: 'individualized',
    title_p2: 'solutions'
};

const AboutSection = ({ footerIndex, animateAboutSection, firstAboutSectionRef, secondAboutSectionRef, thirdAboutSectionRef }) => {

    const [currentSection, setCurrentSection] = useState('first');
    const [secondText, setSecondText] = useState();

    const titleTopRef_v1 = useRef();
    const titleBottomRef_v1 = useRef();
    const pointRefs_v1 = useRef([]);
    const mainTextRef_v1 = useRef();

    const titleTopRef_v2 = useRef();
    const titleBottomRef_v2 = useRef();
    const pointRefs_v2 = useRef([]);
    const mainTextRef_v2 = useRef();

    const titleTopRef_v3 = useRef();
    const titleBottomRef_v3 = useRef();
    const pointRefs_v3 = useRef([]);
    const mainTextRef_v3 = useRef();

    useEffect(() => {
        const mainTextChars_v2 = new SplitType(mainTextRef_v2.current, { types: 'chars words' }).chars;

        setSecondText(mainTextChars_v2);        
    }, [])

    // Main Animation Of Section

    useEffect(() => {
        const mainTextChars_v1 = new SplitType(mainTextRef_v1.current, { types: 'chars words' }).chars;
        
        const titleTopChars_v1 = titleTopRef_v1.current.querySelectorAll('.char');
        const titleBottomChars_v1 = titleBottomRef_v1.current.querySelectorAll('.char');
        const titleTopChars_v2 = titleTopRef_v2.current.querySelectorAll('.char');
        const titleBottomChars_v2 = titleBottomRef_v2.current.querySelectorAll('.char');
       
        
        const allPoints_v1 = pointRefs_v1.current.map(pointRef => pointRef.querySelectorAll('.char'));
        const allPoints_v2 = pointRefs_v2.current.map(pointRef => pointRef.querySelectorAll('.char'));
        
    
        if (mainTextChars_v1.length && titleTopChars_v1.length && firstAboutSectionRef.current) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: firstAboutSectionRef.current,
                    start: 'top 500px',
                    markers: false,
                    pin: false,
                    once: true, 
                    onEnter: () => {
                        gsap.fromTo(mainTextChars_v1,
                            { color: 'rgba(255, 255, 255, 0.50)' },
                            { color: '#FFFFFF', duration: 0.5, stagger: 0.02, scrollTrigger: { trigger: firstAboutSectionRef.current, start: 'top -500px', end: '+=800px', scrub: true, markers: false } }
                        );
                    }
                }
            });
            tl.fromTo(mainTextChars_v1, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.3, stagger: 0.005 })
                .fromTo(titleTopChars_v1, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.3, stagger: 0.07 }, 0)
                .fromTo(titleBottomChars_v1, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.3, stagger: 0.07 }, 0)
                .fromTo(allPoints_v1, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 0.3, stagger: 0.02 }, 0);
        }
    
        if (secondAboutSectionRef.current) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: secondAboutSectionRef.current,
                    start: 'top 20vh',
                    markers: false,
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        setCurrentSection('second');
                        if (window.tl) window.tl.kill();
                        window.tl = gsap.timeline();
                        window.tl.to(titleTopChars_v1, { y: '100%', opacity: 0, duration: 0.3, stagger: -0.05 })
                            .to(titleBottomChars_v1, { y: '100%', opacity: 0, duration: 0.3, stagger: -0.05 }, 0)
                            .to(allPoints_v1, { y: '100%', opacity: 0, duration: 0.3, stagger: -0.02 }, 0)
                            .fromTo(titleTopChars_v2, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: -0.05 }, 0)
                            .fromTo(titleBottomChars_v2, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: -0.05 }, 0)
                            .fromTo(allPoints_v2, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: -0.02 }, 0)
                            .fromTo(mainTextChars_v1, { y: '100%', opacity: 1 }, { y: '0%', opacity: 0, duration: 0.2, stagger: 0.001 }, 0)
                            .fromTo(secondText, { color: 'rgba(255, 255, 255, 0.50)' }, { color: '#FFFFFF', duration: 0.5, stagger: 0.02, scrollTrigger: {
                                trigger: secondAboutSectionRef.current,
                                start: 'top 20vh', 
                                end: 'bottom 80vh', 
                                scrub: true, 
                                markers: false
                            }}, 0)
                            .fromTo(secondText, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.2, stagger: 0.001 }, 0);
                    },
                    onLeaveBack: () => {
                        setCurrentSection('first');
                        if (window.tl) window.tl.kill();
                        window.tl = gsap.timeline();
                        window.tl.to(titleTopChars_v2, { y: '100%', opacity: 0, duration: 0.3, stagger: 0.05 })
                            .to(titleBottomChars_v2, { y: '100%', opacity: 0, duration: 0.3, stagger: 0.05 }, 0)
                            .to(allPoints_v2, { y: '100%', opacity: 0, duration: 0.3, stagger: 0.02 }, 0)
                            .fromTo(titleTopChars_v1, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: 0.05 }, 0)
                            .fromTo(titleBottomChars_v1, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: 0.05 }, 0)
                            .fromTo(allPoints_v1, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: 0.02 }, 0)
                            .fromTo(secondText, { y: '100%', opacity: 1 }, { y: '0%', opacity: 0, duration: 0.2, stagger: 0.001 }, 0)
                            .fromTo(mainTextChars_v1, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.2, stagger: 0.001 }, 0);
                    }
                }
            });
        
            return () => {
                if (tl) tl.kill();
            };
        }
    }, [secondText, firstAboutSectionRef, secondAboutSectionRef]);    



    useEffect(() => {
        const mainTextChars_v3 = new SplitType(mainTextRef_v3.current, { types: 'chars words' }).chars;

    
        const titleTopChars_v2 = titleTopRef_v2.current.querySelectorAll('.char');
        const titleBottomChars_v2 = titleBottomRef_v2.current.querySelectorAll('.char');
        const titleTopChars_v3 = titleTopRef_v3.current.querySelectorAll('.char');
        const titleBottomChars_v3 = titleBottomRef_v3.current.querySelectorAll('.char');
        
        const allPoints_v2 = pointRefs_v2.current.map(pointRef => pointRef.querySelectorAll('.char'));
        const allPoints_v3 = pointRefs_v3.current.map(pointRef => pointRef.querySelectorAll('.char'));

        if (thirdAboutSectionRef.current) {
            console.log('Creating ScrollTrigger for third section');
            const trigger = ScrollTrigger.create({
                trigger: thirdAboutSectionRef.current,
                start: 'top 20vh',
                markers: false, 
                invalidateOnRefresh: true,
                onEnter: () => {
                    console.log('Entered third section');
                    setCurrentSection('third');
                    if (window.tl) window.tl.kill();
                    window.tl = gsap.timeline();
                    window.tl.to(titleTopChars_v2, { y: '100%', opacity: 0, duration: 0.3, stagger: -0.05, onComplete: () => { gsap.to('.about-section', { backgroundColor: '#812456', duration: 0.3 }); } }, 0)
                        .to(titleBottomChars_v2, { y: '100%', opacity: 0, duration: 0.3, stagger: -0.05 }, 0)
                        .to(allPoints_v2, { y: '100%', opacity: 0, duration: 0.3, stagger: -0.02 }, 0)
                        .fromTo(titleTopChars_v3, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: -0.05 }, 0)
                        .fromTo(titleBottomChars_v3, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: -0.05 }, 0)
                        .fromTo(allPoints_v3, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: -0.02 }, 0)
                        .fromTo(secondText, { y: '100%', opacity: 1 }, { y: '0%', opacity: 0, duration: 0.2, stagger: 0.001 }, 0)
                        .fromTo(mainTextChars_v3, { color: 'rgba(255, 255, 255, 0.50)' }, { color: '#FFFFFF', duration: 0.5, stagger: 0.02, scrollTrigger: {
                            trigger: thirdAboutSectionRef.current,
                            start: 'top 20vh',
                            end: '=+800px', 
                            scrub: true, 
                            markers: false
                        }}, 0)
                        .fromTo(mainTextChars_v3, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.2, stagger: 0.001 }, 0);
                },
                onLeaveBack: () => {
                    console.log('Left third section');
                    setCurrentSection('second');
                    if (window.tl) window.tl.kill();
                    window.tl = gsap.timeline();
                    window.tl.to(titleTopChars_v3, { y: '100%', opacity: 0, duration: 0.3, stagger: 0.05, onComplete: () => { gsap.to('.about-section', { backgroundColor: '#691D46', duration: 0.3 }); } }, 0)
                        .to(titleBottomChars_v3, { y: '100%', opacity: 0, duration: 0.3, stagger: 0.05 }, 0)
                        .to(allPoints_v3, { y: '100%', opacity: 0, duration: 0.3, stagger: 0.02 }, 0)
                        .fromTo(titleTopChars_v2, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: 0.05 }, 0)
                        .fromTo(titleBottomChars_v2, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: 0.05 }, 0)
                        .fromTo(allPoints_v2, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.3, stagger: 0.02 }, 0)
                        .fromTo(mainTextChars_v3, { y: '100%', opacity: 1 }, { y: '0%', opacity: 0, duration: 0.2, stagger: 0.001 }, 0)
                        .fromTo(secondText, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.2, stagger: 0.001 }, 0);
                }
            });

            return () => {
                console.log('Killing trigger for third section');
                if (trigger) trigger.kill();
            };
        }
    }, [secondText, thirdAboutSectionRef]);
    
    

    // Styles

    const aboutSectionStyles = classNames('about-section', {
        visible: !footerIndex
    });

    return (
        <section className={aboutSectionStyles}>
            <div className='about-section-container'>
                <div className='about-section-text-block'>
                    <div className='about-section-text-block-number'>
                        {
                            currentSection === 'first' ? '01' :
                            currentSection === 'second' ? '02' :
                            currentSection === 'third' ? '03' :
                            null
                        }
                    </div>
                    <div className='about-section-text-block-chars'>
                        <div className='about-section-text-block-chars-box'>
                            <span className='about-section-text-block-chars-space'>SPACE</span>
                            <span ref={mainTextRef_v1} className='about-section-main-text'>{text_v1.main_text}</span>
                        </div>

                        <div className='about-section-text-block-chars-box'>
                            <span className='about-section-text-block-chars-space'>SPACE</span>
                            <span ref={mainTextRef_v2} className='about-section-main-text'>{text_v2.main_text}</span>
                        </div>

                        <div className='about-section-text-block-chars-box'>
                            <span className='about-section-text-block-chars-space'>SPACE</span>
                            <span ref={mainTextRef_v3} className='about-section-main-text'>{text_v3.main_text}</span>
                        </div>
                        
                    </div>
                </div>

                <div className='about-section-title-block'>
                    <div className='about-section-title-block-points-box'>
                        
                        <div className='about-section-title-block-point'>
                            {
                                text_v1.add_points.map((point, index) => (
                                    <div className='point-item'  key={index}>
                                        <img src={POINT_ICON} alt={'point'}/>
                                        <SplitText tag='div' className='' ref={(el) => (pointRefs_v1.current[index] = el)}>{point}</SplitText>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='about-section-title-block-point'>
                            {
                                text_v2.add_points.map((point, index) => (
                                    <div className='point-item' key={index}>
                                        <img src={POINT_ICON} alt={'point'}/>
                                        <SplitText tag='div' className='' ref={(el) => (pointRefs_v2.current[index] = el)}>{point}</SplitText>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='about-section-title-block-point'>
                            {
                                text_v3.add_points.map((point, index) => (
                                    <div className='point-item'  key={index}>
                                        <img src={POINT_ICON} alt={'point'}/>
                                        <SplitText tag='div' className='' ref={(el) => (pointRefs_v3.current[index] = el)}>{point}</SplitText>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className='about-section-title'>
                        <span className='about-section-title-sb'>advantages & strengths</span>

                        <div className='about-section-title-text'>
                            <div style={{}}>
                                <SplitText tag='div' className='top-text' ref={titleTopRef_v1}>{text_v1.title_p1}</SplitText>
                                <SplitText tag='div' className='bottom-text' ref={titleBottomRef_v1}>{text_v1.title_p2}</SplitText>
                            </div>
                            <div style={{position: 'absolute', top: '0', right: `${window.innerWidth > 800 ? '0' : 'unset'}`, left: `${window.innerWidth > 800 ? 'unset' : '0'}`}}>
                                <SplitText tag='div' className='top-text' ref={titleTopRef_v2}>{text_v2.title_p1}</SplitText>
                                <SplitText tag='div' className='bottom-text' ref={titleBottomRef_v2}>{text_v2.title_p2}</SplitText>
                            </div>
                            <div style={{position: 'absolute', top: '0', right: '0'}}>
                                <SplitText tag='div' className='top-text' ref={titleTopRef_v3}>{text_v3.title_p1}</SplitText>
                                <SplitText tag='div' className='bottom-text' ref={titleBottomRef_v3}>{text_v3.title_p2}</SplitText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection
