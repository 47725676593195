export const servicesData = [
    {
        title: 'GOVERN',
        info: [
            {
                title: 'CYBERSecurity STRATEGY DEVELOPMENT',
                text: 'Cybersecurity strategy development service delivers a customized cybersecurity strategy aligned with our customers business goals. Our commitment to adaptability ensures seamless business operations while assisting customers in achieving their objectives. The strategy encompasses clear milestones, deliverables, and a well-defined timeline, providing clarity for executives. Our team can implement the strategy, or an internal team can utilize it.'
            },
            {
                title: 'BORD/EXECUTIVE CYBERSECURITY TRAINING',
                text: 'Executive Cybersecurity training empowers our customers to cultivate a robust cybersecurity culture. These sessions deliver a comprehensive explanation of a companys susceptibility to cyber-attacks and its risk profile. Through our thorough understanding of our customers requirements, we craft an impactful communication strategy to underscore the critical nature of adhering to cybersecurity protocols. This is essential in ensuring the safety of both executives and the company.'
            },
            {
                title: 'COMPLIANCE AND REGULATORY',
                text: 'Compliance support is a service that helps our customers prepare for passing compliance audits and certifications. We conduct thorough pre-compliance audits to identify control deficiencies and extend comprehensive support to our clients to close discovered gaps. Throughout the compliance auditing process, we are ensuring the provision of pertinent and substantiated evidence. Moreover, our services encompass additional provisions, including penetration testing and security monitoring, to fulfill the certification requirements.'
            },
        ]
    },
    {
        title: 'IDENTIFY',
        info: [
            {
                title: 'PENETRATION TESTING',
                text: 'Penetration testing service provides customers with comprehensive reports about existing vulnerabilities in their IT systems. This service could be different because of the width and depth of interaction with a customer infrastructure. It is split into three different services: vulnerability assessment, penetration testing, and red teaming. These services could be applied to different IT assets such as local or cloud infrastructure, web, mobile, or desktop applications.'
            },
            {
                title: 'Application Security Testing',
                text: 'Application security plays a vital role in modern software development by enabling companies to proactively detect and resolve vulnerabilities, thereby minimizing the potential impact of cyber-attacks. Our team of skilled application security engineers is committed to delivering outstanding service to our clients. We leverage a blend of automated tools and manual reviews to assist our customers development teams in identifying and addressing security gaps at an early stage, resulting in reduced security costs.'
            },
            {
                title: 'Threat landscape monitoring',
                text: 'Threat landscape monitoring is a comprehensive service integrating advanced threat intelligence and external surface monitoring to offer our customers unparalleled visibility into potential threats. By leveraging a proactive approach and tapping into various information sources, we are conducting real-time monitoring of public-facing infrastructure.'
            },
        ]
    },
    {
        title: 'protect',
        info: [
            {
                title: 'Security Awareness Training',
                text: 'Security awareness training is not just a component, but a cornerstone of contemporary cybersecurity resilience. Recent industry research underscores this, revealing that approximately 82 percent of cyberattacks are attributed to human factors. We offer tailored theoretical and practical learning paths, taking into account your cultural background, industry, and the latest cyberattack trends. By aligning your requirements with the motivation of your personnel, we are making effective outcomes for our clients, making you feel informed and aware of the importance of security awareness training.'
            },
            {
                title: 'CyberResiliency',
                text: 'Security awareness training is not just a component, but a cornerstone of contemporary cybersecurity resilience. Recent industry research underscores this, revealing that approximately 82 percent of cyberattacks are attributed to human factors. We offer tailored theoretical and practical learning paths, taking into account your cultural background, industry, and the latest cyberattack trends. By aligning your requirements with the motivation of your personnel, we are making effective outcomes for our clients, making you feel informed and aware of the importance of security awareness training.'
            },
        ]
    },
    {
        title: 'detect',
        info: [
            {
                title: 'security operation center',
                text: 'Security Operations Center (SOC) is a central hub for modern organizations, combining people, processes, and technologies to establish proactive, continuous security monitoring and improvement. Our team is dedicated to providing customers with various service models, such as internal capability processes, outsourced services (SOCaaS), and HybridSOC, which involves dividing different functions between our team and the customers internal team.'
            },
            {
                title: 'Managed Detection and Response',
                text: 'Managed Detection and Response (MDR) service is dedicated to effectively managing advanced cybersecurity tools such as EDR, XDR, and more. This service takes a proactive approach, prioritizing detection, response, management, and fine-tuning these tools. We enable our clients to make the most of their budget and maximize their return on investment. Our team of MDR engineers focuses on understanding customer needs, risk profiles, and threat trends to deliver services and establish a robust cybersecurity environment for our customers.'
            },
            {
                title: 'SecOps Management',
                text: 'SecOps management is a specialized service designed to assist clients in harnessing a diverse range of cybersecurity tools. The intricate nature of modern security operations, attributed to the extensive spectrum of tools and solutions, presents challenges in effective management and optimizing return on investment (ROI). Our approach is proactive, prioritizing critical areas of protection and maximizing ROI across various tools, thereby promoting innovative utilization.'
            },
        ]
    },

    {
        title: 'respond',
        info: [
            {
                title: 'Incident response',
                text: 'Incident response is not just about the implementation of measures to minimize and terminate the adverse impact of cybersecurity incidents, including ransomware attacks. Its about our teams ability to counter modern threats. With our requisite expertise and capabilities, we execute effective strategies. By meticulously addressing attack-chain specifics and discerning attackers modus operandi, we proficiently counter modern threats and mitigate their detrimental effects on our customers infrastructure and operational continuity.'
            },
            {
                title: 'Digital Forensics',
                text: 'Digital forensics involves the systematic collection and preservation of evidence from IT infrastructure. This evidence is crucial for the investigation of criminal activities and incidents within IT systems. Our team is fully equipped to securely gather, store, and deliver a wide range of evidence to assist our clients.'
            },
        ]
    },

    {
        title: 'recover',
        info: [
            {
                title: 'Post Incident activities',
                text: 'Our post-incident activities are comprehensive, covering the recovery of IT infrastructure, lessons-learned exercises, and enhancements of defensive capabilities. Whats more, our team is not just experienced, but possesses extensive expertise in executing these activities. We dont just assist our clients, we partner with them to restore their IT infrastructure and bolster their defensive capabilities. We do this by leveraging the lessons learned and our distinct expertise, ensuring a robust and resilient IT environment.'
            },
        ]
    },
];