export const respondSliderData = [
    {
        text: 'We feel empowered and protected. Their innovative solutions and dedicated team have seamlessly integrated with our business, ensuring that our data remains secure around the clock. Partnering with Amonsul has been instrumental in safeguarding our operations and preserving our reputation. We couldnt be more grateful for their expertise and reliability.',
        position: 'CEO  Bright Brand Solutions',
        name: 'john taylor'
    },

    {
        text: 'We feel empowered and protected. Their innovative solutions and dedicated team have seamlessly integrated with our business, ensuring that our data remains secure around the clock. Partnering with Amonsul has been instrumental in safeguarding our operations and preserving our reputation. We couldnt be more grateful for their expertise and reliability.',
        position: 'CEO  Bright Brand Solutions',
        name: 'john taylor'
    },

    {
        text: 'We feel empowered and protected. Their innovative solutions and dedicated team have seamlessly integrated with our business, ensuring that our data remains secure around the clock. Partnering with Amonsul has been instrumental in safeguarding our operations and preserving our reputation. We couldnt be more grateful for their expertise and reliability.',
        position: 'CEO  Bright Brand Solutions',
        name: 'john taylor'
    },

    {
        text: 'We feel empowered and protected. Their innovative solutions and dedicated team have seamlessly integrated with our business, ensuring that our data remains secure around the clock. Partnering with Amonsul has been instrumental in safeguarding our operations and preserving our reputation. We couldnt be more grateful for their expertise and reliability.',
        position: 'CEO  Bright Brand Solutions',
        name: 'john taylor'
    }, 
]