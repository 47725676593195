import './App.scss';
import CreepingLine from './components/CreepingLine/CreepingLine';
import Header from './components/Header/Header';
import WelcomeSection from './components/WelcomeSection/WelcomeSection';
import { useState, useEffect, useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import ServicesSection from './components/ServicesSection/ServicesSection';
import FormSection from './components/FormSection/FormSection';
import Footer from './components/Footer/Footer';
import AboutSection from './components/AboutSection/AboutSection';
import RespondSection from './components/RespondSection/RespondSection';

function App() {
  const [isLoadingEnded, setLoadingEnded] = useState(false);
  const [isReadyToFix, setReadyToFix] = useState(false);
  const [footerIndex, setFooterIndex] = useState(false);
  const [animateAboutSection, setAnimateAboutSection] = useState(false);

  const firstAboutSectionRef = useRef();
  const secondAboutSectionRef = useRef();
  const thirdAboutSectionRef = useRef();
  
  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <div className="App">
      <Header isLoadingEnded={isLoadingEnded} isReadyToFix={isReadyToFix}/>
      <WelcomeSection isLoadingEnded={isLoadingEnded} setLoadingEnded={setLoadingEnded} isReadyToFix={isReadyToFix} setReadyToFix={setReadyToFix}/>
      <CreepingLine isLoadingEnded={isLoadingEnded}/>
      <ServicesSection setAnimateAboutSection={setAnimateAboutSection}/>
      <AboutSection footerIndex={footerIndex} animateAboutSection={animateAboutSection} firstAboutSectionRef={firstAboutSectionRef} secondAboutSectionRef={secondAboutSectionRef}  thirdAboutSectionRef={thirdAboutSectionRef}/>
      <div id='about' ref={firstAboutSectionRef} style={{height: `calc(100vh + 800px)`, width: '100%', pointerEvents: 'none', backgroundColor: 'green', opacity: '0', zIndex: '2', position: 'relative', transform: 'none !important'}}></div>
      <div ref={secondAboutSectionRef} style={{height: `calc(100vh + 800px)`, width: '100%', pointerEvents: 'none', backgroundColor: 'blue', opacity: '0', zIndex: '2', position: 'relative'}}></div>
      <div ref={thirdAboutSectionRef} style={{height: `calc(100vh + 800px)`, width: '100%', pointerEvents: 'none', backgroundColor: 'blue', opacity: '0', zIndex: '2', position: 'relative', transform: 'none !important'}}></div>
      {/* <RespondSection/> */}
      <FormSection isReadyToFix={isReadyToFix} setFooterIndex={setFooterIndex}/>
      <div style={{height: '0', width: '100%', marginBottom: '100vh', pointerEvents: 'none', backgroundColor: 'transparent', zIndex: '2'}}></div>
      <Footer footerIndex={footerIndex}/>
    </div>
  );
}

export default App;
