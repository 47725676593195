import React, { forwardRef } from 'react';

const SplitTextComponent = forwardRef(({ tag: Tag, children, className }, ref) => {
  const splitText = (text) => {
    return text.split(/(<br\s*\/?>)/gi).map((segment, index) => {
      if (segment.toLowerCase().startsWith('<br')) {
        return <br key={index} />;
      }

      return segment.split(' ').map((word, wordIndex, wordsArray) => (
        <React.Fragment key={`${index}-${wordIndex}`}>
          <span className="word">
            {word.split('').map((char, charIndex) => (
              <span key={`${index}-${wordIndex}-${charIndex}`} className="char">
                {char}
              </span>
            ))}
          </span>
          {wordIndex < wordsArray.length - 1 && (
            <span className="space">&nbsp;</span>
          )}
        </React.Fragment>
      ));
    });
  };

  const renderChildren = (children) => {
    if (typeof children === 'string') {
      return splitText(children);
    }
    return React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return splitText(child);
      } else if (React.isValidElement(child) && child.type === 'br') {
        return <br />;
      }
      return child;
    });
  };

  return (
    <Tag ref={ref} className={className}>
      {renderChildren(children)}
    </Tag>
  );
});

export default SplitTextComponent;
